import { IconType } from 'types/icons';

export const IconLogo: IconType = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 597 600"
			fill='currentColor'>
      <path d="M186.979 -0.507922C307.424 -0.8522 427.87 0.229675 548.314 -0.522235C575.579 -0.679343 597.498 19.9937 597.42 47.388C597.663 79.0236 597.243 110.678 597.655 142.309C597.354 168.41 573.456 186.796 548.883 187.617C383.5 187.617 216.18 188.061 50 188C50 216.38 50 244.76 50 274C77.9988 273.321 105.991 272.839 134 273C133.858 251.328 134 231 134 209C149.453 208.622 163.063 212.7 174 224C185.438 238.389 186.118 256.452 186 274C261.525 273.826 337.064 274.362 412.585 273.613C440.209 273.81 460.528 297.2 460.404 324.043C460.572 349.917 460.38 375.791 460.515 401.665C460.6 420.65 459.297 434.353 445.75 448.688C431.549 462.295 416.33 462.435 397.707 462.293C372.805 462.228 347.902 462.139 323 462C323.108 490.381 323.236 518.763 323.227 547.144C323.45 573.543 304.801 598.436 277.406 599.617C244.069 601.198 210.717 601.043 177.383 599.498C152.87 598.005 134.383 575.41 134.639 551.597C134.441 482.734 134.828 413.864 135 345C164.093 344.633 185.043 366.68 185.681 395.058C187.268 446.357 186.061 497.69 186 549C214.38 549 242.76 549 272 549C272 503.46 272 457.92 272 411C317.21 411 362.42 411 409 411C409 382.62 409 354.24 409 325C393.117 325.008 393.117 325.008 376.913 325.016C342.979 325.032 309.045 325.043 275.111 325.051C197.384 325.07 119.562 327.922 41.8754 324.487C22.4149 323.39 -0.0412059 305.098 -0.147171 284.309C-0.400953 250.109 -0.0877475 215.894 -0.508789 181.697C-0.321242 155.043 24.053 136.841 49.1562 136.586C77.4346 136.128 105.718 135.824 134 136C133.842 111.13 134.173 86.2612 134.12 61.3916C134.09 42.6216 134.11 28.0694 147.748 14.0215C159.281 2.45953 171.046 -0.607389 186.979 -0.507922ZM186 50C186 78.71 186 107.42 186 137C305.968 137.066 426.5 137 546 136C546.966 107.313 547 78.7225 547 50C427.87 50 308.74 50 186 50Z" />
    </svg>
  );
};